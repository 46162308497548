<template>
  <v-main color="transparent">
    <Toolbar />
    <div>
      <Hero />
      <IntellectualProperty />
      <Timeline />
      <LeadershipTeam />
      <AdvisoryBoard />
      <Testimonials />
    </div>
    <Footer />
  </v-main>
</template>

<script>

import Toolbar from '@/components/header/Toolbar.vue'
import Hero from '@/components/about/Hero.vue'
import IntellectualProperty from '@/components/about/IntellectualProperty.vue'
import LeadershipTeam from '@/components/about/LeadershipTeam.vue'
import AdvisoryBoard from '@/components/about/AdvisoryBoard.vue'
import Testimonials from '@/components/about/Testimonials.vue'
import Timeline from '@/components/about/Timeline.vue'
import Footer from '@/components/footer/Footer.vue'

export default {
  components: {
    Toolbar,
    Hero,
    IntellectualProperty,
    LeadershipTeam,
    AdvisoryBoard,
    Testimonials,
    Timeline,
    Footer
  }
}
</script>
