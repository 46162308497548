<template>
  <v-sheet id="leadership">
    <v-container class="py-4 py-lg-8">
      <div style="width: 80px; height: 4px" class="mb-3 secondary" />
      <h2 class="text-h3 text-lg-h2 mb-3">Leadership Team</h2>
      <div class="text-h6 text-lg-h5 mt-4">Our Leadership Team comprises experienced technologists, researchers and medical professionals.</div>
      <v-row class="mt-6">
        <v-col
          v-for="(item, i) in team"
          :key="i"
          cols="12"
          sm="6"
          lg="3"
          class="text-center"
        >
          <v-card to="#">
            <v-img :src="item.image" height="340" position="center 30%"></v-img>
          </v-card>
          <div class="pa-3">
            <div class="text-h5">{{ item.name }}</div>
            <div class="text-h6 secondary--text">{{ item.title }}</div>
            <v-btn text :href="item.linkedin" icon color="primary lighten-1">
              <v-icon>mdi-linkedin</v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
export default {
  data() {
    return {
      team: [{
        name: 'Nilanjan Banerjee, Ph.D.',
        title: 'Co-Founder & CEO',
        image: require('@/assets/images/employees/Banerjee.jpg'),
        linkedin: 'https://www.linkedin.com/in/nilanjan-banerjee-48609910'
      }, {
        name: 'Cody Feltch',
        title: 'Co-Founder & CTO',
        image: require('@/assets/images/employees/Feltch.jpg'),
        linkedin: 'https://www.linkedin.com/in/codyfeltch'
      }, {
        name: 'Justin Brooks, M.D., Ph.D.',
        title: 'Chief Science Officer',
        image: require('@/assets/images/employees/Brooks.jpg'),
        linkedin: 'https://www.linkedin.com/in/justin-brooks-m-d-ph-d-a7a32633'
      }, {
        name: 'Sanjay K. Agarwal, MBA, MD',
        title: 'Chief Medical Officer',
        image: require('@/assets/images/employees/Sanjay.png'),
        linkedin: 'https://www.linkedin.com/in/sanjay-agarwal-aa64521b'
      }]
    }
  }
}
</script>
