<template>
  <v-container id="advisory-board" class="py-4 py-lg-8">
    <div style="width: 80px; height: 4px" class="mb-3 secondary" />
    <h2 class="text-h3 text-lg-h2 mb-3">Advisory Board</h2>
    <div class="text-h6 text-lg-h5 mt-4">Our Advisory Board includes leading experts in sleep medicine and business leaders in the medical device industry.</div>

    <v-card class="pa-4 mt-6">
      <v-row>
        <v-col
          v-for="(item, i) in team"
          :key="i"
          cols="12"
          sm="6"
          lg="4"
          xl="3"
        >
          <div class="d-flex align-center">
            <div class="mr-2">
              <v-avatar size="95">
                <v-img :src="item.image"></v-img>
              </v-avatar>
            </div>
            <div>
              <div class="text-h5">{{ item.name }}</div>
              <div class="text-h7 secondary--text">{{ item.title }}</div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      team: [{
        name: 'Christopher Earley, MBBCh, Ph.D.',
        title: 'Professor of Neurology at Johns Hopkins University. Recognized leader in RLS, ADHD, and leg movement disorders during sleep.',
        image: require('@/assets/images/employees/Earley.jpg'),
        linkedin: '#'
      }, {
        name: 'Daniel Picchietti, MD',
        title: 'Neurologist in Urbana, Illinois and affiliated with Carle Foundation Hospital. Recognized expert in pediatric sleep medicine.',
        image: require('@/assets/images/employees/Picchietti.jpg'),
        linkedin: '#'
      }, {
        name: 'Lourdes DelRosso, M.D.',
        title: 'Sleep physician in Seattle Children’s hospital. Recognized expert in pediatric sleep medicine.',
        image: require('@/assets/images/employees/Delrosso.jpg'),
        linkedin: '#'
      }, {
        name: 'Michael Smith, Ph.D',
        title: 'Professor of Neurology at Johns Hopkins University. Leading expert in insomnia, sleep and pain, and behavioral sleep medicine.',
        image: require('@/assets/images/employees/Smith2.jpg'),
        linkedin: '#'
      }, {
        name: 'Ryan Robucci, Ph.D.',
        title: 'Professor of Computer Engineering and lead expert in signal processing and electronics design.',
        image: require('@/assets/images/employees/Robucci.jpg'),
        linkedin: '#'
      }, {
        name: 'Soumendra Mandal',
        title: 'Senior Engineer at Intel and expert in hardware and firmware design.',
        image: require('@/assets/images/employees/Soumendra.jpg'),
        linkedin: '#'
      }, {
        name: 'Chris D. Geddes, Ph.D.',
        title: 'Leader in sales and marketing and angel investor',
        image: require('@/assets/images/employees/Geddes.jpg'),
        linkedin: '#'
      }, {
        name: 'Micheal Thomas',
        title: 'Business leader and innovation catalyst in medtech, digital health, medical device, and healthcare services. ',
        image: require('@/assets/images/employees/Thomas.jpg'),
        linkedin: '#'
      }]
    }
  }
}
</script>
