<template>
  <v-sheet id="timeline" color="transparent">
    <v-container class="py-4 py-lg-8">
      <div style="width: 80px; height: 4px" class="mb-3 secondary" />
      <h2 class="text-h3 text-lg-h2 mb-3">Company Timeline</h2>
      <div class="text-h6 text-lg-h5 mt-4">Tanzen was formed through collaboration between UMBC Researchers and Johns Hopkins Sleep Medicine.</div>
      <div class="w-50">
        <v-timeline class="my-2 mx-lg-12" :class="$vuetify.breakpoint.lgAndUp ? '' : 'v-timeline--dense'">
          <v-timeline-item
            v-for="(item, i) in items"
            :key="i"
            color="primary lighten-1"
            small
          >
            <template v-slot:opposite>
              <span class="text-h6">{{ item.year }}</span>
            </template>
            <v-card class="elevation-1">
              <v-card-title class="text-h5" style="word-break: normal;">{{ item.title }}</v-card-title>
              <v-card-subtitle :class="$vuetify.breakpoint.lgAndUp ? 'd-none' : 'd-block'">{{ item.year }}</v-card-subtitle>
              <v-card-text class="text-body-1 text-sm-h6">
                {{ item.value }}
              </v-card-text>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </div>
    </v-container>
  </v-sheet>
</template>

<script>
export default {
  data() {
    return {
      items: [{
        year: '2014',
        title: 'MII Phase I and NSF PFI-AR Grants',
        value: 'Prototype development for multi-sensor ankle band and recording software. Market research for RLS and ADHD diagnosis/management.'
      }, {
        year: '2015',
        title: 'Textile Capacitor Prototyping',
        value: 'Development of patented capacitive textile sensors for motion tracking.'
      }, {
        year: '2016',
        title: 'Formation of Tanzen Medical Inc.',
        value: 'Collaboration between Johns Hopkins Sleep Physicians and UMBC Engineering Professors.'
      }, {
        year: '2017',
        title: 'MII Phase III',
        value: 'Market research, feasibility and initial FDA discussion.'
      }, {
        year: '2018',
        title: 'NSF SBIR Phase I ',
        value: 'Feasibility study of periodic leg movement during sleep and arousal detection. NSF SBIR Phase I: In-home Monitoring of Sleep Fragmentation and Micro-Arousals by Characterizing Leg Movements - 1819626'
      }, {
        year: '2019',
        title: 'RestEaze Professional Released',
        value: 'Sold in limited quantities for use in sleep research studies.'
      }, {
        year: '2020',
        title: 'Angel Investment and Industrial Redesign',
        value: 'Full hardware redesign for a BLE enabled dual-core ARM processor. New fabric band designed by Twinery/MAS Holdings.'
      }, {
        year: '2021',
        title: 'FDA Breakthrough Device Designation and MIPS funding',
        value: 'Received an FDA Breakthrough Device Designation for monitoring of PLMS.'
      }]
    }
  }
}
</script>
