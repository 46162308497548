<template>
  <v-sheet id="intellectual-property" class="">
    <v-container class="py-4 py-lg-8">
      <v-row>
        <v-col cols="12" lg="6" xl="7">
          <h2 class="text-h3 text-lg-h2 mt-0 mt-lg-3 mt-xl-10">Intellectual Property</h2>
          <div class="text-h6 text-lg-h5 mt-6">Tanzen is aggressively pursuing Intellectual Property related to RestEaze including hardware, software, and analytic components. Tanzen
            has published research papers in peer-reviewed journals.</div>
          <!-- <v-btn color="primary" large class="mt-6">Explore demo</v-btn> -->
        </v-col>
        <v-col cols="12" lg="6" xl="5">
          <v-sheet elevation="6" class="pa-2" rounded>
            <v-carousel
              v-model="model"
              :show-arrows="false"
              cycle
              height="450"
              hide-delimiter-background
              :light="!$vuetify.theme.isDark"
            >
              <v-carousel-item v-for="(item, i) in carousel" :key="i">
                <v-sheet>
                  <v-responsive max-width="450" class="mx-auto text-center">
                    <div class="text-h4 mt-8">{{ item.title }}</div>
                    <div class="text-body-1 mt-5">{{ item.text }}</div>
                    <div class="mt-4">
                      <router-link :to="item.link.to" class="text-decoration-none font-weight-bold text-h6">{{ item.link.title }}</router-link>
                    </div>
                  </v-responsive>
                </v-sheet>
              </v-carousel-item>
            </v-carousel>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
export default {
  data: () => ({
    model: 0,
    carousel: [{
      title: 'Patent for Capacitive Sensing System',
      text: 'System and method for proximity-based position, movement, and gesture detection using capacitive sensor arrays, US 10,228,801 B2, Patent Issued in the U.S., March 2019 (Licensed by Tanzen from Univ. of Maryland)',
      link: {
        title: 'Learn More',
        to: '#'
      }
    }, {
      title: 'Patent for Movement Detection Approach',
      text: 'Device and Detection of Periodic Leg Movements, US 10,335,085 B2, Patent Issued in the U.S., Patent pending in Canada, Australia, South Korea. (Licensed by Tanzen from Johns Hopkins University)',
      link: {
        title: 'Learn More',
        to: '#'
      }
    }, {
      title: 'Patent for Multi-sensor Ankle Band',
      text: 'Detection and Measurement of body part measurements using capacitive sensors and inertial sensors, US 2017/0156640 A1, Patent application pending in the U.S., EU, Canada, South Korea. (Licensed by Tanzen from Univ. of Maryland)',
      link: {
        title: 'Learn More',
        to: '#'
      }
    }, {
      title: 'Patent for Early Infection Detection',
      text: 'Individualized, Peripheral Inflammatory Response Detection and Monitoring, US 15590001PR, Provisional application (Owned by Tanzen)',
      link: {
        title: 'Learn More',
        to: '#'
      }
    },
    {
      title: 'Research paper on LMS and Cortical Arousal Detection',
      text: 'Pilot study: can machine learning analyses of movement discriminate between leg movements in sleep (LMS) with vs. without cortical arousals? (Sleep and Breathing, 2020)',
      link: {
        title: 'Learn More',
        to: '#'
      }
    },
    {
      title: 'Research paper on power consumption optimization of RestEaze',
      text: 'RestEaZe: Low-power accurate sleep monitoring using a wearable multi-sensor ankle band (Smart Health, 2020)',
      link: {
        title: 'Learn More',
        to: '#'
      }
    }]
  })
}
</script>

<style scoped>
.full-screen{
  min-height: calc(100vh - 60px);
}
</style>