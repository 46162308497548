<template>
  <v-sheet>
    <v-container class="py-4 py-lg-8">
      <div style="width: 80px; height: 4px" class="mb-3 secondary" />
      <h2 class="text-h4 mb-3">What are experts saying about RestEaze?</h2>
      <v-row>
        <v-col
          v-for="(item, i) in testimonials"
          :key="i"
          cols="12"
          sm="6"
          lg="3"
        >
          <v-card class="pa-3 text-body-1" color="surface" flat>
            {{ item.testimonial }}
          </v-card>
          <div class="d-flex align-center mt-3">
            <div class="mr-2">
              <v-avatar size="60">
                <v-img :src="item.image"></v-img>
              </v-avatar>
            </div>
            <div>
              <div class="text-h5">{{ item.name }}</div>
              <div class="text-h6 secondary--text">{{ item.title }}</div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
export default {
  data() {
    return {
      testimonials: [{
        name: 'Lourdes M. DelRosso, M.D., MS',
        image: require('@/assets/images/employees/Delrosso.jpg'),
        title: 'Sleep Specialist, Seattle Children’s Hospital',
        testimonial: '“As you know there are many children who have very restless sleep including many with ADHD and RLS. [RestEaze] would help us evaluate both diagnosis and treatment efficacy.” '
      }, {
        name: 'Narong Simakajornboon, M.D.',
        title: 'Cincinnati Children’s Hospital',
        image: require('@/assets/images/employees/simakajornboon.jpg'),
        testimonial: '“We see many children with restless sleep and sleep related movement disorders. It would be very helpful to be able to monitor these children to evaluate the need for treatment and to assess short and long-term treatment outcomes. [RestEaze] could help guide treatments. Currently, there are limited tool to guide the treatment. Your monitor would address a significant unmet need for pediatric sleep medicine” '
      }, {
        name: 'Daniel Picchietti, M.D.',
        title: 'FAASM, Fellow, American Academy of Sleep Medicine',
        image: require('@/assets/images/employees/Picchietti.jpg'),
        testimonial: '“[RestEaze] will meet a critical need for pediatric sleep medicine, with multiple-night, at-home sleep evaluations specifically designed for evaluating restless sleep in children. Your system enables evaluation of both diagnosis and treatment efficacy.”'
      }, {
        name: 'Christopher Earley, M.B.B.Ch., Ph.D.',
        title: 'Prof. of Neurology, Johns Hopkins School of Medicine',
        image: require('@/assets/images/employees/Earley.jpg'),
        testimonial: '“The RestEaze device is  easy to use and comfortable to wear while providing a remarkable level of detail on leg movements throughout the sleep period.”'
      }]
    }
  }
}
</script>
